import { BASE_URL, COMPANY_URL, IS_DEV, LEARNER_URL, MANAGER_URL, TEACHER_URL } from './configs';

const MANAGER = IS_DEV ? `http://localhost:3001` : MANAGER_URL;
const COMPANY = IS_DEV ? `http://localhost:3004` : COMPANY_URL;
const TEACHER = IS_DEV ? `http://localhost:3002` : TEACHER_URL;
const LEARNER = IS_DEV ? `http://localhost:3003` : LEARNER_URL;
const COMMON = IS_DEV ? `http://localhost:3100` : BASE_URL;
const OFFICIAL = 'https://hanasu-plus.kurashi-study.net/';

export const HANASU_PLUS_URL = { MANAGER, COMPANY, TEACHER, LEARNER, COMMON, OFFICIAL };
