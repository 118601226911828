import { unified } from 'unified';
import { createElement } from 'react';
import rehypeReact from 'rehype-react';
import remarkParse from 'remark-parse';
import slug from 'remark-slug';
import remarkRehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';
import rehypeSanitize from 'rehype-sanitize';
import rehypeRaw from 'rehype-raw';
const processor = unified()
  .use(remarkParse) // マークダウンパーサー
  .use(slug) // id
  .use(remarkRehype, { allowDangerousHtml: true }) // マークダウンからHTMLに変換
  .use(rehypeRaw) // HTMLタグを使用
  // FIXME なくても表示できる　HASTに変換する必要があるのか？必要なければ削除
  .use(rehypeStringify, { allowDangerousHtml: true }) // コンパイラー
  .use(rehypeSanitize) // scriptタグ無効化
  .use(rehypeReact, { createElement }); // React Element に変換

export default processor;
