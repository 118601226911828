import '../_assets/styles/index.scss';
import '../_assets/styles/github-markdown.min.css'
import { NavLink } from 'react-router-dom';

export default function TermsHeader() {
  return (
    <div className="terms-header">
      <NavLink to="/terms" className="terms-header-button" activeClassName='terms-header-button-active'>利用規約</NavLink>
      <NavLink to="/privacy" className="terms-header-button" activeClassName='terms-header-button-active'>プライバシーポリシー</NavLink>
      <NavLink to="/transactions" className="terms-header-button" activeClassName='terms-header-button-active'>特定商取引法</NavLink>
    </div>
  );
}