import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * 独自エラーコード
 * @returns
 */
export const useErrorPageURLParams = (): [string] => {
  const location = useLocation();
  const queryStrings = new URLSearchParams(location.search);
  const [errorCode, setErrorCode] = useState<string>('');

  // URLクエリパラメータ名
  const queryParams = {
    errorCode: 'errorCode',
  } as const;

  useEffect(() => {
    // FIXME 文字列リテラル
    const errorCode = queryStrings.get(queryParams.errorCode);
    setErrorCode(errorCode ?? 'E_9000');
  }, [queryParams]);

  return [errorCode];
};
