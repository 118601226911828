import { useEffect, useState } from 'react';

/**
TODO
markdown内の画像をモジュール（import card from "../_assets/images/card.jpg"）で読み込んだ画像にするためには
モジュールで読み込んだsrc※とmarkdownに記載した画像パスをマッピングして変換が必要。
単純に親ディレクトリの置換では上手くいかない。

※モジュールで読み込んだsrc
8192バイト以下ではbase64、それより大きい場合は画像パスでインポートされる
これはWebpackの仕様
*/
export default function useMarkdown(markdownPath: string) {
  const [markdown, setMarkdown] = useState('');
  useEffect(() => {
    fetch(markdownPath).then(async (res) => {
      const text = await res.text();
      setMarkdown(text);
    });
  }, [markdownPath]);
  return { markdown };
}
