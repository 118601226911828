import React, { lazy } from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Router } from 'react-router-dom';
import { Switch } from 'react-router';
import { ErrorPage } from './pages/error';
import Terms from './pages/terms/index';
import Privacy from './pages/privacy/index';
import Transactions from './pages/transactions/index';
import { Shell } from './components/Shell/shell';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Shell>
          <Switch>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/transactions">
              <Transactions />
            </Route>
            <Route path="/error">
              <ErrorPage />
            </Route>
            <Route path="/*">
              <ErrorPage />
            </Route>
          </Switch>
        </Shell>
      </div>
    </BrowserRouter>
  );
}

export default App;
