export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
export const AUTO_BACK_TO_TOP_TIME = 5;

// FIXME 定義チェックいる？テストで担保する？
export const MANAGER_PORT = IS_DEV ? process.env.REACT_APP_MANAGER_PORT ?? '' : '';
export const COMPANY_PORT = IS_DEV ? process.env.REACT_APP_COMPANY_PORT ?? '' : '';
export const TEACHER_PORT = IS_DEV ? process.env.REACT_APP_TEACHER_PORT ?? '' : '';
export const LEARNER_PORT = IS_DEV ? process.env.REACT_APP_LEARNER_PORT ?? '' : '';
export const COMMON_PORT = IS_DEV ? process.env.REACT_APP_COMMON_PORT ?? '' : '';

export const BASE_URL = IS_DEV ? 'http://localhost:3100' : process.env.REACT_APP_BASE_URL ?? '';
export const MANAGER_URL = IS_DEV
  ? `http://localhost:${MANAGER_PORT}`
  : process.env.REACT_APP_MANAGER_URL ?? BASE_URL;
export const COMPANY_URL = IS_DEV
  ? `http://localhost:${COMPANY_PORT}`
  : process.env.REACT_APP_COMPANY_URL ?? BASE_URL;
export const TEACHER_URL = IS_DEV
  ? `http://localhost:${TEACHER_PORT}`
  : process.env.REACT_APP_TEACHER_URL ?? BASE_URL;
export const LEARNER_URL = IS_DEV
  ? `http://localhost:${LEARNER_PORT}`
  : process.env.REACT_APP_LEARNER_URL ?? BASE_URL;
export const API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
