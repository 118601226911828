import { useEffect, useState } from 'react';

export const useErrorMessage = (errorCode: string = ''): [string] => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    let message = '';
    if (errorCode.includes('404')) {
      message = `[404] お探しのページは見つかりませんでした`;
    } else if (errorCode.includes('401')) {
      message = '[401] 認証エラーが発生しました。再度サインインしてください。';
    } else {
      message = `[500] エラーが発生しました`;
    }
    setErrorMessage(message);
  }, [errorCode]);
  return [errorMessage];
};
