import { Box, Container, Flex } from '@chakra-ui/react';
import { routerActions } from 'connected-react-router';
import React, { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './shell.scss';
import { useLocation } from 'react-router-dom';
import { ASSETS_IMG } from '../../utils/assets';

export interface IShellProps {
  children: React.ReactNode;
}

export var currentUserId = React.createContext('');

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const [companyId, setCompanyId] = useState<any>('くらしスタディ はなすプラス');
  const onClickBrand = () => dispatch(routerActions.push({ pathname: '/top' }));
  const { pathname } = useLocation();

  useEffect(() => {
    return;
  });

  if (pathname.includes('room-call')) {
    return <Container className="room-call-div-container">{children}</Container>;
  }

  return (
    <>
      <Box
        display="flex"
        height="60px"
        borderBottom="1px solid #ddd"
        justifyContent="space-between"
        boxShadow={'sm'}
        alignItems="center"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <Box className="Logo" onClick={onClickBrand} style={{ cursor: 'pointer' }}>
            <img src={ASSETS_IMG.logo01} alt={companyId} />
          </Box>
          <br />
          <sub style={{ color: '#3f83bf', marginLeft: '5px', bottom: 'unset' }}>
            {companyId !== undefined && companyId.length > 0 ? '  ' : ''}
          </sub>
        </Box>
      </Box>
      <Flex>
        <Container maxWidth={'100%'} padding={'0'} className="Shell-children">
          {children}
        </Container>
      </Flex>
    </>
  );
});
