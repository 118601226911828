import userEvent from '@testing-library/user-event';
import { Dispatch, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AUTO_BACK_TO_TOP_TIME,
  COMMON_PORT,
  COMPANY_PORT,
  IS_DEV,
  IS_STG,
  LEARNER_PORT,
  MANAGER_PORT,
  TEACHER_PORT,
} from '../../../constants/configs';
import { HANASU_PLUS_URL } from '../../../constants/urls';

export type PageSorce = 'learner' | 'manager' | 'teacher' | 'company' | 'common';

export const usePageNavigation = () => {
  // TODO はなすプラスのメインドメインの時に使用
  const history = useHistory();
  const referrer = document.referrer;
  const [targetUrl, setTargetUrl] = useState<string>('');
  const [message, setMessage] = useState<string>(
    `${AUTO_BACK_TO_TOP_TIME}秒後にトップに戻ります。`,
  );
  const [remainingTime, setRemainingTime] = useState<number>(AUTO_BACK_TO_TOP_TIME);

  // FIXME　デフォルトをはなすプラスの公式サイトにする
  const hadleNavigateScreen = (url: string = '/') => {
    if (targetUrl !== HANASU_PLUS_URL.COMMON) {
      document.location.href = url;
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    const getSource = (): PageSorce => {
      if (IS_DEV) {
        const port = referrer.match(/(?<=:)\d+/) ?? '3100';
        const portMap: { [key in string]: PageSorce } = {
          [MANAGER_PORT]: 'manager',
          [TEACHER_PORT]: 'teacher',
          [LEARNER_PORT]: 'learner',
          [COMPANY_PORT]: 'company',
          [COMMON_PORT]: 'common',
        };
        try {
          if (port) {
            return portMap[port[0]];
          } else {
            return 'common';
          }
        } catch (error) {
          return 'common';
        }
      } else {
        const subDomain = referrer.match(/(?<=:\/\/)[^\.]*/);
        if (subDomain) {
          return subDomain[0] as PageSorce;
        } else {
          return 'common';
        }
      }
    };
    const source = getSource();

    const targetURLMap: { [key in PageSorce]: string } = {
      manager: HANASU_PLUS_URL.MANAGER,
      company: HANASU_PLUS_URL.COMPANY,
      learner: HANASU_PLUS_URL.LEARNER,
      teacher: HANASU_PLUS_URL.TEACHER,
      common: HANASU_PLUS_URL.COMMON,
    };
    try {
      setTargetUrl(targetURLMap[source as PageSorce]);
    } catch (error) {
      setTargetUrl(targetURLMap['common']);
    }
  }, []);

  useEffect(() => {
    // FIXME 時間通りに遷移できない場合があるので、カウントダウンはしないほうがいい？
    setTimeout(() => {
      hadleNavigateScreen(targetUrl);
    }, AUTO_BACK_TO_TOP_TIME * 1000);
    // const interval = setInterval(() => {
    //   setMessage(`${remainingTime}秒後にトップに戻ります。`);
    //   if (remainingTime > 0) {
    //     setRemainingTime(remainingTime - 1);
    //   } else {
    //     hadleNavigateScreen(targetUrl);
    //   }
    // }, 1000);
    return () => {
      // clearInterval(interval);
      // setRemainingTime(AUTO_BACK_TO_TOP_TIME);
    };
  }, [
    targetUrl,
    // remainingTime
  ]);

  return { targetUrl, message, hadleNavigateScreen };
};
