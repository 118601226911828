import processor from '../../utils/markdown';
import md from '../../_assets/markdown/privacy.md';
import useMarkdown from '../../hooks/useMarkdown';
import TermsHeader from '../../components/TermsHeader';

export default function Terms() {
  const { markdown } = useMarkdown(md);
  const MD = processor.processSync(markdown).result;
  return (
    <>
      <div className="terms-body">
        <TermsHeader />
        <div className="card markdown-body">{MD}</div>
      </div>
    </>
  );
}
