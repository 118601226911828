import { useParams, useHistory } from 'react-router-dom';
import { AUTO_BACK_TO_TOP_TIME } from '../../constants/configs';
import { HANASU_PLUS_URL } from '../../constants/urls';
import { useErrorMessage } from './hooks/useErrorMessage';
import { usePageNavigation } from './hooks/usePageNavigation';
import { useErrorPageURLParams } from './hooks/useErrorPageUrlParams';
import './styles/index.scss';
interface IErrorProps {}

export type PageSorce = 'learner' | 'admin' | 'teacher' | 'company' | 'common';

export const ErrorPage = ({}: IErrorProps) => {
  const { targetUrl, message, hadleNavigateScreen } = usePageNavigation();
  const [errorCode] = useErrorPageURLParams();
  const [errorMessage] = useErrorMessage(errorCode);

  return (
    <>
      <div className="warpper">
        <h1>Error</h1>
        <div className="error-code">
          <p>{errorMessage}</p>
          <p>ERROR_CODE: {errorCode}</p>
        </div>
        <p>{message}</p>
        <button onClick={() => hadleNavigateScreen(targetUrl)}>TOPへ戻る</button>
      </div>
    </>
  );
};
